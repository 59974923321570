body {
  font-size: 13px;
}
.topbar,
.sidebar .sidebar-brand,
.topbar .nav-item .nav-link {
  height: 40px;
}
.sidebar .nav-item .nav-link {
  padding: 10px;
}
.sidebar hr.sidebar-divider {
  margin: 0px;
}
.sidebar {
  width: 268px !important;
  height: 100vh;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbr-track {
  background-color: #d2d6de;
}
::-webkit-scrollbar-thumb {
  background-color: #a19e96;
}
.card-header {
  padding-left: 0px;
  padding-right: 0px;
  margin: 0px;
}
.form-control {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  border-radius: 0px;
}
.input-group > .input-group-append > .btn {
  height: 30px;
  border-radius: 0px;
  padding: 0px 3px;
  font-size: 13px;
}
.modal-header {
  padding: 5px 10px;
}
.form-group {
  margin-bottom: 5px;
}
.modal-footer {
  padding: 5px;
}
.modal-body {
  padding: 10px;
}
.modal-header .close {
  padding: 5px;
  padding-top: 0px;
  margin: 0px;
}
.form-group .select {
  padding: 0px;
}

.frmtimkiem {
  padding-top: 5px;
}
table thead {
  padding-left: 1.25rem;
  table-layout: fixed;
}
table tbody tr {
  /* display:table; */
  width: 100%;
  table-layout: fixed;
}
.table td,
.table th {
  padding: 5px;
}
.lop-ban-do table tbody tr,
.lop-ban-do table thead,
.thong-tin table tbody tr,
.thong-tin table thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.lop-ban-do table tbody {
  height: calc(50vh - 85px);
  display: block;
  overflow: auto;
}
.lop-ban-do table tbody tr,
.lop-ban-do table thead,
.thong-tin table thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.table {
  margin-bottom: 5px;
}
.table .col-checkbox {
  width: 26px;
  padding: 3px 6px;
  vertical-align: middle;
}
.table .col-checkbox .form-check {
  padding-left: 13px;
}
.table .col-checkbox .form-check-input {
  position: initial;
}
.col-checkbox .form-check-input {
  margin-left: -1rem;
}
.table thead .col-checkbox {
  vertical-align: baseline;
}
.col-checkbox,
table thead tr th {
  text-align: center;
}
.navbar-expand .navbar-nav li button {
  margin-top: 5px;
}
.form-group {
  margin-bottom: 5px;
}
.modal-title {
  line-height: 1;
}
.modal-header .close {
  padding: 10px;
}
.card-body {
  padding: 0px;
}
.container-fluid {
  padding-left: 5px;
  padding-right: 5px;
}
.card {
  border-radius: 0px;
}

.sidebar.toggled .nav-item .nav-link span {
  font-size: 13px;
}
.public .sidebar .nav-item .nav-link i {
  font-size: 25px;
}
.ban-do {
  height: calc(100vh - 126px);
  padding: 0px;
}
.footer-map {
  height: 30px;
  line-height: 30px;
  padding-top: 2px;
}
.footer-map .col-md-2 {
  padding-left: 0px;
}

.nav-item .bg-white {
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.map-cong-cu .btn {
  margin-bottom: 10px;
  float: left;
}
.do-khoang-cach {
  top: calc(100vh - 270px);
  left: 0.5em;
}
.do-dien-tich {
  top: calc(100vh - 235px);
  left: 0.5em;
}
.do-vi-tri {
  top: calc(100vh - 200px);
  left: 0.5em;
}
.tk-ban-do {
  margin-left: -20px;
  z-index: 999;
  padding-top: 8px;
  font-size: 16px;
}
.ban-do .tk-nang-cao {
  top: 6px;
  left: 50px;
  border-radius: 16px;
}
.ban-do .left-lop-ban-do {
  top: 50px;
  left: 50px;
  border-radius: 16px;
}
.ban-do .tk-nang-cao button,
.ban-do .left-lop-ban-do button {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: white;
}
.tk-nang-cao i,
.left-lop-ban-do i {
  color: #484848;
}
.ban-do .tk-nang-cao button:hover i,
.ban-do .left-lop-ban-do button:hover i {
  color: white;
}
.col-checkbox .fa-info-circle,
.col-checkbox .fa-edit {
  font-size: 18px;
  cursor: pointer;
}
.table {
  color: #484848;
}
.sidebar-brand-icon .logo {
  height: 50px;
}
.bd-button .btn {
  margin-left: 3px;
}
.bd-admin .lop-ban-do table tbody {
  height: calc(50vh - 110px);
  display: block;
  overflow: auto;
}

.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
}
.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}
.ol-tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}
.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.ol-tooltip-static:before {
  border-top-color: #ffcc33;
}
.ctrl-active {
  background-color: green !important;
}
button:focus {
  outline: none;
}
.bg-gradient-primary {
  background-image: linear-gradient(180deg, #329f12 10%, #329f12 100%);
}
.sidebar-dark .nav-item .nav-link {
  color: white;
  cursor: pointer;
}
.modal-title {
  line-height: 1.5;
}
.sidebar-dark .nav-item .nav-link i {
  color: white;
}
.sidebar-dark .nav-item .nav-link:hover {
  color: #fff;
}
.sidebar-dark .nav-item .nav-link:active i,
.sidebar-dark .nav-item .nav-link:focus i,
.sidebar-dark .nav-item .nav-link:hover i {
  color: #f2e701;
}
.tkdientich .col-form-label {
  padding-left: 3px;
  padding-right: 3px;
}
.tkdientich .select-dt {
  padding-left: 0px;
  padding-right: 0px;
}
.tkdientich .select-dt select {
  padding-left: 5px;
  padding-right: 5px;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item {
  cursor: pointer;
}
.suathongtin {
  float: right;
  line-height: 1px;
  margin-right: 5px;
}
table .mau-ky-hieu {
  width: 60px;
  vertical-align: middle;
}
.mau-ky-hieu div {
  text-align: center;
  width: 50px;
  border: 1px solid rgb(117, 117, 117);
}
.mau-ky-hieu div span {
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  color: black;
  font-weight: 600;
}
.table thead th {
  vertical-align: middle;
}
.tb-chu-giai {
  padding-top: 5px;
}
.tb-chu-giai tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.tb-chu-giai tbody tr:hover {
  background-color: aquamarine;
}
.tb-chu-giai tbody tr {
  cursor: pointer;
}
.tb-chu-giai .group {
  font-weight: 700;
}
.tb-chu-giai .group td {
  padding-left: 38px;
}
.sweet-loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 30%);
  top: 0;
  left: 0;
  z-index: 9999;
}

.table-wraper {
  max-height: calc(100vh - 160px);
  overflow: scroll;
}

.table-wraper tbody {
  max-height: calc(100% - 250px);
  /* overflow: scroll;
  overflow-x: hidden; */
  /* width: 100%; */
}

.modal-body .table-responsive {
  max-height: calc(100vh - 340px) !important;
}

.modal-xlarger .modal-dialog {
  max-width: 1000px;
}
.modal-xlarger embed {
  height: calc(100vh - 200px);
  height: calc(100vh - 200px);
}
.col-so {
  text-align: right;
}
.kieu-so {
  text-align: right;
}
.pb-tieu-de {
  color: blue;
  padding: 8px;
}
.ten-pm {
  font-size: 20px;
  color: #329f12;
  padding-left: 10px;
  font-weight: bold;
  margin-bottom: 0px;
}
.pagination {
  margin-bottom: 0px;
}
.col-trangthai {
  max-width: 150px;
}
.ket-qua-tim-kiem {
  font-size: 16px;
  color: red;
  padding-top: 5px;
}
.frm-huong-dan {
  padding: 10px;
}
.ban-do .tk-nang-cao button:hover,
.ban-do .left-lop-ban-do button:hover {
  background: rgba(0, 60, 136, 0.7);
}
.btn-dang-nhap {
  margin-top: 5px;
}
.topbar .topbar-divider {
  margin: auto 0.5rem;
}

.label {
  margin-right: 5px;
}

.modal-content .ck-content {
  max-height: 200px;
}
.h-solieu > div {
  padding-left: 2px;
  padding-right: 2px;
}
.navbar-expand-md .navbar-nav .nav-link {
  cursor: pointer;
}
.custom-control .home-ban-do {
  height: calc(100vh - 60px);
  padding: 0px;
}
@media (max-width: 600px) {
  .map-ghichu {
    overflow: auto;
    height: calc(100vh - 125px);
  }
  .navbar-expand .navbar-nav {
    display: none;
  }
}
.td-number-style {
  text-align: right;
}
