.modalTK{
    width: 35% ;
    max-width: none !important;
}

.modalLH{
    width: 50% ;
    max-width: none !important;
}

.nhanLH{
    text-align: right;
    font-weight: bold;
}

.NhanCBox{
    font-weight: bold;
}

.KhungImg{
    margin-left: 12px;
    border: 1px solid rgba(197, 191, 191, 0.904);
}

.modal-body .react-tabs div .ck-content
{
    min-height: 50vh ;
}

.modal-body div .ck-content
{
    min-height: 30vh ;
}

.rowTK{
    width: 100%;
    border: 1px solid  rgba(197, 191, 191, 0.904);
}

.nhanTK{
    text-align: right ;
}

.eRor {
    color: red;
    font-style: italic;
}

.alertDel {
    background-color: steelblue;
}

.SelectRow:active{
    background-color: rgba(182, 41, 123, 0.89);
    color: #FFF;
}

.SelectRow:hover{
    background-color: rgba(41, 103, 182, 0.89);
    color: #FFF;
}

.card .row a:disabled{
    background-color: rgba(112, 128, 144, 0.89);
}
