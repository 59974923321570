.cls-status-label {
  color: white;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

a.label:hover, a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777777;
}

.label-default[href]:hover, .label-default[href]:focus {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #c9302c;
}

.custom-switch {
  padding-left: 0px !important;
}

/* css cho tree component */

.tree-component {
  height: 100%;
}

.rc-tree-list {
  height: 100%;
}

.rc-tree-list-holder {
  height: 100%;
  max-height: none !important;
}

.rc-tree-node-content-wrapper {
  height: 25px !important;
}

input[type='file'] {
  height: 50px;
}

.ck-editor__editable {
  min-height: 250px;
}

.col-img {
  width: 70px;
}

.col-img img {
  max-width: 60px;
  max-height: 60px;
  padding: 2px;
  border: 1px solid gainsboro;
}

.col-img-bv img {
  width: 100%;
  max-height: 250px;
  border: 1px solid gainsboro;
}

.col-img-bv-default {
  color: #000;
}

.col-img-bv-default img {
  width: 100%;
  max-height: 70px;
  border: 1px solid gainsboro;
}

.col-img-bv-default-content img {
  width: 100%;
  min-height: 150px;
  max-height: 150px;
  border: 1px solid gainsboro;
}

.bv-body {
  color: black;
}

.bv-background {
  background-color: rgba(247, 239, 239, 0.9);
}

.bv-body a {
  color: black;
  text-decoration: none;
}

.bv-body a:hover {
  color: #449d44;
  font-weight: bold;
}

.bv-scoll {
  height: 380px;
  overflow-y: scroll;
  padding: 1rem;
}

.bv-sticky {
  position: fixed;
}

.bv-img-hienthibaiviet img {
  width: 100%;
  text-align: center;
}

/* checkbox */

.form-group .form-check-input {
  margin-left: -1.25em;
}

.col-status {
  width: 100px;
}

.custom-checkbox {
  margin-left: 1.25em;
}

.file-style {
  color: #337ab7;
  font-size: large;
  text-align: center;
  width: 100px;
}

.public-container {
  width: 100%;
}

.bv-content {
  text-align: center;
  max-height: 100px;
  overflow: hidden;
  text-overflow: '...';
}

.bv-hot-content {
  color: black;
}

.bv-hot-content-text {
  max-height: 140px;
  overflow: hidden;
  text-overflow: '...';
}

.bv-default-content {
  max-height: 70px;
}

.bv-default-content-text {
  max-height: 70px;
  overflow: hidden;
  text-overflow: '...';
}

.bv-content-hot {
  margin-top: 9px;
}

.bv-img-hot img {
  width: 100%;
  align-content: center;
  min-height: 65px;
  max-height: 65px;
  border: 1px solid gainsboro;
}

.bv-text-hot {
  max-height: 75px;
  overflow: hidden;
  text-overflow: '...';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.vertical-line {
  border-right: 1px solid #07c900;
  height: 100px;
  max-height: 200px;
  margin-left: auto;
  margin-right: auto;
  width: 1px;
}

.logo-baiviet {
  max-height: 100px;
}

.banner-baiviet {
  max-height: 270px;
  display: inline block;
  overflow: hidden;
  background: center;
}

.text-menu-bv {
  font-size: 1.5rem;
}

.text-menu-bv li :hover {
  font-weight: unset;
  color: #07c900 !important;
}

.text-menu-bv li :active {
  color: #1506e9 !important;
}

.table-import {
  display: block;
}

.import-scroll {
  max-height: 700px;
  overflow-y: scroll;
}

.sau-benh-chi-tiet img {
  max-width: 100%;
  max-height: 300px;
  /*   max-height: 400px; */
}
.sau-b-cont img {
  padding: 5px;
}
.banner-baiviet img {
  height: 100%;
  width: 100%;
  position: inherit;
  transform: translateY(-60%);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ajs-modal {
  z-index: 9999 !important;
}

.custom-modal-style .modal-content {
  width: 1000px;
}

@media (min-width: 992px) {
  .custom-modal-style .modal-lg, .custom-modal-style .modal-xl {
    max-width: 1000px;
  }
}

.modal-custom {
  width: auto !important;
  height: auto !important;
  left: 20% !important;
  top: 40px;
}

.modal-backdrop {
  height: auto;
  width: auto;
}

#modallienhe .modal-dialog, #modalgioithieu .modal-dialog, #modalgopy .modal-dialog {
  width: 800px;
  max-width: 1024px;
}

#modalbaocao .modal-dialog, #modalhuongdan .modal-dialog, #modalhoidap .modal-dialog {
  width: 1024px;
  max-width: 1024px;
}

.link-file {
  cursor: pointer;
}

.table-solieu .table-responsive {
  max-height: calc(100vh - 160px)!important;
}

/* #modalhuongdan .modal-body {
  height: calc(100vh - 340px)!important;
} */

#modalhuongdan .row {
  height: calc(100vh - 200px)!important;
}

hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.trang-container {
  min-height: calc(100vh - 570px);
}

.trang-content {
  padding-top: 20px;
}

.breadcrumb-container {
  height: 3em;
  line-height: 3em;
  font-size: large;
}

.breadcrumb-line {
  border-bottom: 1px solid #bb8787;
  box-shadow: 2px 1px 3px 1px #e2e2e2;
}

.breadcrumb-dis {
  color: darkgray;
  font-weight: bold;
}

.breadcrumb-active {
  color: gray;
  font-weight: bold;
}

.breadcrumb-dis:hover, .breadcrumb-active:hover {
  text-decoration: none;
}

.main-content {
  padding: 20px 10px;
}

#modalchuandoansaubenh .modal-body {
  max-height: calc(100vh - 200px)!important;
  overflow-y: scroll;
  overflow-x: hidden;
}
#modalchuandoansaubenh .modal-dialog {
  width: 800px;
  max-width: 1024px;
  margin-bottom: 22px;
}


.caytrong-container {
  color: black;
}

.caytrong-container img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
  border: 1px solid gray;
  border-radius: 100px;
}

.caytrong-container button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  min-width: 100px;
}

.trieuchung {
  padding-left: 0px;
  padding-right: 0px;
}

.trieuchung-container {
  margin-bottom: 10px;
  padding-top: 5px;
  border-radius: 20px;
  padding-left: .45rem;
  padding-right: .45rem;
  margin-left: 1px;
}

.trieuchung-container img {
  display: block;
  width: 100%;
  height: 180px;
  border-radius: 20px;
}

.trieuchung-container .trieuchung-mota {
  height: 7.5em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}

.trieuchung-xemchitiet{
  display: block;
  text-align: center;
  font-style: italic;
  cursor: pointer;
}

.sau-benh-chi-tiet-hinh-anh-trieu-chung img {
  margin-right: 5px;
  max-width: 200px;
  max-height: 150px;
  min-width: 200px;
  min-height: 150px;
}

.selected-dacdiemtrieuchung {
  background-color: lightgray;
}

.selected-dacdiemtrieuchung div {
  color: rgb(0, 0, 0);
}

.trieuchung-hinhanh-chitiet{
  max-height: 250px;
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.btn-chuandoan {
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.bieu-do {
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 22px;
}

.ketquachuandoan img{
  width: 100%;
  max-height: 125px;
}

.ketquachuandoan div{
  text-align: justify;
}


.table-import {
  display: block;
}


.modal-dien-tich-san-xuat{
  display: block;
}

.modal-dien-tich-san-xuat-scroll {
  
  max-height: 700px;
  overflow-y: scroll;
}

.col-trangthai{
  text-align: center;
  width: 100px;
}
.mobile #content {
  margin-top: 0px;
}
.mobile .ban-do {
  height: calc(100vh - 78px);
}

.table-solieu {
  height: calc(100vh - 78px);
}
.table-solieu  tbody{
  overflow: scroll;
}