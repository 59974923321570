.huongdan-danhsach{
    border-right: 1px solid lightgray;
}

.huongdan-danhsach div{
    padding: 5px;
    cursor: pointer;
}

.huongdan-danhsach div:hover{
    /* padding: 5px;
    cursor: pointer; */
    color: lightseagreen;
    font-weight: bold;
    text-decoration: underline;
}

.huongdan-tieude{
    font-weight: bold;
    text-decoration: underline;
}

.huongdan-noidung{
    padding-top: 5px;
}
.huongdan-danhsach, .hd-noi-dubng {
    height: 100%;
    overflow: auto;
}