.navbar .logo {
  height: 50px;
  width: auto;
  padding-right: 10px;
}
img {
  max-width: 100%;
}
.navbar a {
  color: white;
}
.navbar a:hover {
  color: #f2e701;
}
.img-fluid {
  width: 100%;
  max-height: 400px;
}
.fl-builder-content {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}
.navbar {
  background-color: green;
  border-bottom: 1px solid white;
}
.fl-group {
  padding: 20px 10px;
}
.fl-row-fixed-width {
  max-width: 1100px;
}

.bg-bak {
  background-color: green;
  color: white;
}
.page-nav-icon {
  background-position: 0 0;
  background-repeat: no-repeat;
  cursor: pointer;
  display: block;
  height: 52px;
  margin-top: 30px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 52px;
}
.page-nav-text {
  color: white;
  cursor: pointer;
  display: block;
  font-size: 16px;
  margin-bottom: 30px;
  text-align: center;
  font-family: "Avenir LT W05_35 Light", Arial, Helvetica, sans-serif !important;
}
.fl-photo-align-center {
  display: inline-block;
  line-height: 0;
  position: relative;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.fl-row-content-wrap {
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: scroll;
  background-size: cover;
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-left-width: 0px;
}
.tinh-nang img {
  height: 100px;
  width: 100px;
}
.footer {
  background-color: green;
}
.f-logo,
.f-mxh {
  float: left;
}
.f-thong-tin {
  float: left;
  padding-left: 10px;
  padding-top: 10px;
  color: white;
}
.f-logo img {
  height: 120px;
  width: 120px;
}
.footer .container {
  min-height: 150px;
  padding: 10px;
}
.f-thong-tin p {
  margin-bottom: 0px;
}
.f-mxh {
  padding-left: 10px;
  padding-top: 30px;
  width: 150px;
  float: right;
}
.f-mxh .fa-facebook-square {
  color: #41a9f3;
}
.f-mxh .fa-youtube {
  color: #c70000;
}
.f-mxh .fa-twitter-square {
  color: #005d94;
}
.f-mxh i {
  font-size: 30px;
  padding-left: 10px;
}
a {
  color: #3c3d3e;
}
.tinh-nang a {
  color: white;
}
.lh-thong-tin {
  padding-top: 10px;
  padding-bottom: 10px;
}
.lh-phan-hoi {
  margin-top: 10px;
}
.bd-content {
  background-color: #d0d0d0;
  margin-top: 50px;
}

.tt-item {
  float: left;
  width: 265px;
  text-align: center;
}
.tt-item .tt-img img {
  width: 250px;
  height: 160px;
}
.tt-item .tt-ten p {
  margin-top: 10px;
}
.media .tt-noi-bat {
  height: 65px;
  width: 80px;
}
.media-body a p {
  height: 75px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.p-content {
  background-color: white;
  padding-top: 10px;
  min-height: calc(100vh - 200px);
}
.dropdown:hover > .dropdown-menu {
  display: block;
  padding: 0px;
  /* max-height: calc(100vh - 80px);
    overflow: auto; */
}
.dropdown-menu {
  border-radius: 0px;
  padding: 3px 10px;
  margin: 0px;
}
.navbar .dropdown-menu a {
  color: #4c4b4b;
}
.navbar .dropdown-menu a:active,
.navbar .dropdown-item.active {
  color: white;
}
.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
  background-color: #52a2f3;
  color: white;
}
.dropdown .dropdown-menu .dropdown-submenu {
  left: 100%;
  margin-top: -33px;
  margin-left: -1px;
}
.dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]::after {
  border-bottom: 0.35em solid transparent;
  border-left: 0.35em solid;
  border-right: 0;
  border-top: 0.35em solid transparent;
  margin-left: 0.3rem;
  right: 5px;
  position: absolute;
  margin-top: 8px;
}
.breadcrumb-container {
  width: 100%;
}
.navbar {
  padding: 0px;
}
.public #content {
  margin-top: 50px;
}
.sau-b-cont {
  padding-right: 20px;
}
.f-thong-tin a {
  color: white;
}
.lst-sau-benh {
  padding: 5px 0px;
  border-bottom: 1px solid #d0d0d0;
}
.lst-sau-benh .a-img {
  height: 100px;
  width: 100px;
  text-align: center;
}
.lst-sau-benh .a-img img {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
}
.lst-sau-benh .media-body {
  padding-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.card-body .import-scroll {
  max-height: calc(100vh - 140px);
}
form .tab-pane {
  padding-top: 5px;
}
.card-header input[type="file"] {
  height: 30px;
}
.input-group > .input-group-append > .btn {
  min-width: 30px;
}
.navbar-nav .nav-link {
  cursor: pointer;
}
.caytrong-container > img {
  cursor: pointer;
}
.navbar-nav .nav-item .active {
  color: #f2e701;
}
.sau-benh-chi-tiet {
  text-align: center;
}
.media {
  width: 100%;
}
.home-bt {
  padding: 10px;
  margin: 0px;
  background: white;
}
.home-bt .ol-viewport {
  border: gray solid 1px;
}
.fl-group .col2 {
  width: 14.2%;
  line-height: 0.6;
}
@media (max-width: 600px) {
  .map-ghichu {
    overflow: auto;
    height: calc(100vh - 125px);
  }
  .navbar-expand .navbar-nav {
    display: none;
  }
  .navbar-collapse {
    padding-left: 20px;
  }
  .navbar-toggler {
    margin-right: 20px;
  }
  .navbar-toggler i {
    color: white;
    font-size: 24px;
  }
  .f-thong-tin h5 {
    font-size: 14px;
  }
  .f-logo {
    padding-right: 5px;
  }
  .tt-item {
    width: auto;
  }
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  .media {
    padding-left: 10px;
    padding-right: 10px;
  }
  .sau-b-cont {
      padding-left: 10px;
      padding-right: 10px;
  }
  .f-thong-tin {
      float: none;
  }
  .fl-group .col2 {
    width: 33.3%;
  }
}
