.hinh-anh {
    height: calc(100vh - 300px);
    border: 1px solid #e4e4e4;
    padding: 5px;
    margin-top: 5px;
    overflow: auto;
}
.thu-muc{
    height: calc(100vh - 300px);
    border: 1px solid #e4e4e4;
    padding: 5px;
    margin-top: 5px;
}
input[type=file] {
    color: transparent;
}
.progress{ height: 10px;}
.table tr>td .progress {
    margin: 0;
}
.column-so-thu-tu span{
    cursor: pointer;
}
.table-condensed{
    margin-bottom: 5px;
}
.table-condensed tbody{
    height: 200px;;
}
.box-chieu-cao .box-body {
    min-height: calc(100vh - 193px);
}

.hinh-anh .col-xs-6, .hinh-anh .col-md-3{
    padding-left: 5px;
    padding-right: 5px;
}
.image-select{
    border: 1px solid #4377e6;
    background: #bee0f3;
}
.thumbnail-img{
    cursor: pointer;
    padding: 6px 2px;
}
.thumbnail-img span {
    /* display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
}
.table-responsive{
    max-height: 300px;
}
.column-so-thu-tu{
    width: 30px;
}
.column-chuc-nang{
    width: 30px;
}
/* .modal {
    z-index: 9999;
} */
.icon-tap-tin i {
    font-size: 100px;
    padding-bottom: 10px;
}
.icon-tap-tin {
    height: 120px;
}
.thumbnail-img img {
    width: 100%;
    height: 100%;
    border: 1px solid gainsboro;
    padding: 3px;
}

.thumbnail img{
    max-height: 100px;
    max-width: 200px;
    padding:5px;
    border: 1px solid gainsboro;
}

.quanlytaptin > .row{
    width: 100%;
}

.quanlytaptin button{
    margin: 0px 3px;
}
.title-taptin-container{
    /* line-height: 3em; */
}

.title-taptin-content{
    display: -webkit-box;
    line-height: 1.4em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

