.sidebar {
    height: calc(100vh - 80) !important;
    display: flex;
    flex-direction: column;
    border-right: 1px solid;
    border-radius: 0;
    border-color: rgba(64, 194, 133, 0.693);
    transition: 0.2s ease;
    z-index: 101;
    position: absolute;
    border: 1px solid rgb(113 204 160);
}
.togglemenu {
    height: 90px;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 25px;
    position: absolute;
    outline: none;
    /* z-index: 1; */
    z-index: 101;
    background-color: rgba(64, 194, 133, 0.693);
    border-color: rgba(64, 194, 133, 0.693);
    border-right: 0;
    margin-top: calc(50vh - 120px);
}
.togglemenu:focus{
    outline: none;
}
.sidecontent {
    width: 295px;
    background-color: rgb(255, 255, 255);
    height: calc(100vh - 95px);
    overflow: auto;
}
.togglemenu i {
    font-size: 20px;
    margin-left: -4px;
    color: white;
}
.outer {
    background-color: pink;
    position: relative;
    display: inline-block;
  }
  
  .inner {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    padding-left: 10px;
    width: 100px;
    font-weight: 500;
  }
  
  .rotate {
      -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
  }