.sidebar {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  border-right: 1px solid;
  border-radius: 0;
  border-color: rgba(64, 194, 133, 0.693);
  background-color: rgb(255, 255, 255);
  transition: 0.2s ease;
  z-index: 101;
  position: absolute;
  border: 1px solid rgb(113 204 160);
  padding: 5px;
}
.togglemenu {
  height: 50px;
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 25px;
  position: absolute;
  outline: none;
  /* z-index: 1; */
  z-index: 101;
  background-color: rgba(64, 194, 133, 0.693);
  border-color: rgba(64, 194, 133, 0.693);
  border-right: 0;
  margin-top: -5px;
}
.togglemenu:focus {
  outline: none;
}
.sidecontent {
  /* width: 100px; */
  background-color: rgb(255, 255, 255);
  height: calc(100vh - 140px);
  overflow: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
.togglemenu i {
  font-size: 20px;
  margin-left: -4px;
  color: white;
}
.tieude {
  color: blue;
  text-align: center;
}
.frmtimkiem {
  padding-top: 5px;
}
