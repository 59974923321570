.sidebar {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    border-right: 1px solid;
    border-radius: 0;
    border-color: rgba(64, 194, 133, 0.693);
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease;
    z-index: 101;
    position: absolute;
    border: 1px solid rgb(113 204 160);
}
.togglemenu {
    height: 50px;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 25px;
    position: absolute;
    outline: none;
    /* z-index: 1; */
    z-index: 101;
    background-color: rgba(64, 194, 133, 0.693);
    border-color: rgba(64, 194, 133, 0.693);
    border-right: 0;
}
.togglemenu:focus{
    outline: none;
}
.sidecontent {
    /* width: 100px; */
    background-color: rgb(255, 255, 255);
}
.togglemenu i {
    font-size: 20px;
    margin-left: -4px;
    color: white;
}
.mapbando table tbody tr, .mapbando table thead {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .mapbando table tbody {
    height: calc(100vh - 142px);
    display: block;
      overflow: auto;
  }
  .mapbando table thead{
      width: calc(100% - 5px);
  }
.mapbando table {
    margin-bottom: 0px;
}
.mngroup {
    font-weight: 600;
}
.gcactive {
    color: blue;
}
.gc {
    color: #c3c3c3;
}
.scolpan {
    cursor: pointer;
}
.scolpan > div > i {
    font-size: 18px;
    float: right;
}
.mapbando table tbody .rowan {
    display: none;
}
.colsapxep i {
    font-size: 18px;
    display: block;
    height: 10px;
}
.colsapxep .xuong i {
    margin-top: -10px;
    height: 18px;
}
td.colsapxep {
    padding: 0!important;
}
th.colsapxep {
    padding: 5px;
    cursor: pointer;
}
th.colsapxep i {
    font-size: 18px;
    color: blue;
}
th.colsapxep i:hover {
    color: green;
}