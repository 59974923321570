.footernote {
    display: flex;
    flex-direction: column;
    border-top: 1px solid;
    border-radius: 0;
    /* border-color: rgba(64, 194, 133, 0.693); */
    background-color: rgb(255, 255, 255);
    transition: 0.2s ease;
    z-index: 101;
    position: absolute;
    border: 1px solid rgb(113 204 160);
    width: 100%;
    /* overflow: auto; */
}
.footermenu {
    height: 25px;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 90px;
    position: absolute;
    outline: none;
    /* z-index: 1; */
    z-index: 101;
    background-color: rgba(64, 194, 133, 0.693);
    border-color: rgba(64, 194, 133, 0.693);
    border-right: 0;
    margin-left: calc(50% - 25px);
}
.footermenu:focus{
    outline: none;
}
.footermenu i {
    font-size: 20px;
    margin-top: -10px;
    margin-left: -9px;
    color: white;
    position: absolute;
}
.ten {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.mapghichu {
    margin-left: 0px;
  margin-right: 0px;
  overflow: auto;
  max-height: 248px;
  /* padding: 10px 15px; */
}
@media(max-width: 600px){
    .mapghichu {
        max-height: calc(100vh - 125px);
    }
}
.chugiaimau{
  float: left;
  margin-left: 10px;
  margin-right: 10px;
  width: 50px;
  border: 1px solid rgb(117, 117, 117);
  text-align: center;
    text-shadow: -1px 0 white, 0 1px white,      1px 0 white, 0 -1px white;
    color: black;
}
.mapghichu .formcheck {
    height: 25px;
    cursor: pointer;
  }