/* .modal-header-style{
    width: 100%;
}

.cauhoi-hinhanh {
    height: 100px;
    max-width: 100%;
    padding: 2px;
    border: 1px solid gainsboro;
}

.cauhoi-row{
    height: 100px;
}

.traloi-link{
    font-style: italic;
    font-weight: bold;
    font-size: smaller;
}

.like-style{
    color: #4e73df;
}

.traloi-link i{
    padding: 0px 10px;
}

.traloi-link i:hover{
    cursor: pointer;
}

.traloi-container{
    background-color: ghostwhite;
    padding: 20px;
    max-height: 250px;
    overflow: scroll;
}

.hoten{
    padding-left: 15px;
    font-style: italic;
    font-weight: bold;
    color: darkgray;
    font-size: smaller;
}

.created-date{
    padding-left: 15px;
    font-style: italic;
    color: darkgray;
    font-size: smaller;
}

.thumb-user{
    height: 40px;
    width: 40px;
    border:1px solid gainsboro;
    border-radius: 40px;
    float: left;
}
.test{
    padding-left: 10px;
    float: left;
}

.show-hinhanh{
    max-height: 500px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
} */

.modal-header-style {
    width: 100%;
}

.cauhoi-container {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 10px;
    border: 1px solid gainsboro;
    border-radius: 10px;
    box-shadow: 2px 1px 3px 1px gainsboro;
}

.cauhoi-avatar {
    height: 50px;
    width: 50px;
    padding: 2px;
    border: 1px solid gainsboro;
    border-radius: 50px;
    float: left;
}

.cauhoi-hinhanh {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 300px;
    max-width: 100%;
    padding: 2px;
}

.cauhoi-row {
    height: 100px;
}

.cauhoi-themmoi {
    border: 1px solid gray;
    line-height: 1.5em;
    /* height: 3em; */
    padding: 10px;
    border-radius: 30px;
    background-color: gainsboro;
    font-size: large;
    color: green;
    font-weight: bold;
    box-shadow: 2px 2px 4px 1px gainsboro;
}

.traloi {
    padding-top: 20px;
}

.traloi-link {
    /* font-style: italic;
    font-weight: bold;
    font-size: smaller; */
    border-top: 1px solid gainsboro;
    border-bottom: 1px solid gainsboro;
    padding: 5px;
    margin: 5px;
}

.like-style {
    color: #4e73df;
    font-weight: bold;
}

.traloi-link i {
    padding: 0px 10px;
}

.traloi-link i:hover {
    cursor: pointer;
}

.traloi-container {
    background-color: ghostwhite;
    padding: 20px;
    /* max-height: 250px;
    overflow: scroll; */
}

.traloi-button {
    border-top: 1px solid gainsboro;
    padding: 5px;
}

.traloi-button button {
    margin-left: 5px;
}

.traloi-close {
    padding-right: 10px;
}

.hoten {
    padding-left: 15px;
    font-weight: bold;
}

.created-date {
    padding-left: 15px;
    font-style: italic;
    color: darkgray;
    font-size: smaller;
}

.thumb-user {
    height: 40px;
    width: 40px;
    border: 1px solid gainsboro;
    border-radius: 40px;
    float: left;
}

.test {
    padding-left: 10px;
    float: left;
}

.show-hinhanh {
    max-height: 500px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.clearfix {
    clear: both;
}

.form-cauhoi {
    padding: 10px;
}

.dang-nhap {
    font-weight: bold;
    font-style: italic;
    color: dodgerblue;
    cursor: pointer;
}

input[type="file"] {
    color: gray !important;
    width: 100%;
}

a{
    color: gray;
}